import { Injectable } from '@angular/core';
import { CheckBoxField } from '../shared/form-field/checkbox-field';
import { DropdownField } from '../shared/form-field/dropdown-field';
import { FormField } from '../shared/form-field/form-field';
import { TextboxField } from '../shared/form-field/textbox-field';
import { of } from 'rxjs';
import { optionEllipsisHeaderOptions } from '../catalog/catalog.constant';
import { DropdownDataService } from './dropdown.data.service';
import { PhonePattern } from '../shared/constants/validation-regex';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { ClaimsLookup } from '../shared/constants/lookup-list';
import { ImportFileService } from '../shared/import-file/import-file.service';
import { AutocompleteField } from '../shared/form-field/autocomplete-field';
import { ZipCodePlus4Pattern } from '../shared/constants/validation-regex';
import { copy, removeDuplicatesFromArray } from '../shared/utilities/common-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';

@Injectable()
export class ContractFormFieldService {
  authDetails: IRoleAuthorizationService;
  // TODO: get from a remote source of formField metadata
  constructor(
    private dropdownDataService: DropdownDataService,
    private roleService: RoleAuthorizationService,
    private importFileService: ImportFileService,
    private claimsLookupService: ClaimsLookup,
    private azureService: AzureLoginService,
  ) {}
  getFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        isSingleselect: true,
        options: [
          { key: 'Active', value: 'Active' },
          { key: 'Expired', value: 'Expired' },
          { key: 'Suspended', value: 'Suspended' },
          { key: 'Void', value: 'Void' },
          { key: 'Cancelled', value: 'Cancelled' },
          { key: 'Pending', value: 'Pending' },
          { key: 'Unprocessed', value: 'Unprocessed' },
          { key: 'Suspended-Cancelled', value: 'Suspended-Cancelled' },
        ],
        order: 1,
      }),

      new TextboxField({
        key: 'contractNumber',
        label: 'Contract Number',
        disabled: true,
        order: 2,
      }),

      new TextboxField({
        key: 'externalId',
        label: 'Ref #Sales Receipt',
        order: 3,
        hasPermissions: true,
        permissions: {
          type: 'input',
          operation: 'EDIT',
          permission: 'Reference Number',
          category: 'Contract',
        },
      }),

      new TextboxField({
        key: 'insurerName',
        label: 'Insurance',
        order: 4,
      }),

      new TextboxField({
        key: 'obligor_',
        label: 'Obligor',
        order: 5,
      }),
      new TextboxField({
        key: 'activationDate',
        label: 'Activation Date',
        type: 'Date',
        order: 6,
      }),
      new TextboxField({
        key: 'uniqueId',
        label: 'Unique ID',
        disabled: true,
        order: 7,
      }),
      new TextboxField({
        key: 'dop',
        label: 'DOP',
        disabled: true,
        order: 8,
      }),

      // new DropdownField({
      //   key: 'cultureCode',
      //   label: 'Culture Code',
      //   isSingleselect: true,
      //   options: [{ key: 'en-US', value: 'en-US' }, { key: 'en-PH', value: 'en-PH' }],
      //   order: 8
      // }),
      new TextboxField({
        key: 'effectiveDate',
        label: 'Effect Date',
        type: 'Date',
        disabled: true,
        order: 9,
      }),
      new TextboxField({
        key: 'printedDate',
        label: 'Printed Date',
        type: 'Date',
        order: 10,
      }),
      new TextboxField({
        key: 'effectiveBasedOn',
        label: 'Effective Based On',
        disabled: true,
        order: 11,
      }),

      new TextboxField({
        key: 'expirationDate',
        label: 'Exp Date',
        type: 'Date',
        disabled: true,
        order: 12,
      }),

      new TextboxField({
        key: 'shipDate',
        label: 'Ship Date',
        type: 'Date',
        order: 13,
      }),

      new TextboxField({
        key: 'transactionDate',
        label: 'NL Transaction Date',
        type: 'Date',
        order: 14,
        hasPermissions: true,
        permissions: {
          type: 'input',
          operation: 'EDIT',
          permission: 'NL Transaction Date',
          category: 'Contract',
        },
      }),
      new CheckBoxField({
        key: 'unprocessedFlag',
        label: '',
        type: 'checkbox',
        options: [{ key: 'unprocessedFlag', value: 'UNPROCESSED Flag' }],
        disabled: true,
        order: 15,
      }),
      new TextboxField({
        key: 'agentNumber',
        label: 'Agent Number',
        order: 18,
      }),
      new TextboxField({
        key: 'saleDate',
        label: 'Sale Date',
        type: 'Date',
        order: 19,
      }),
      new TextboxField({
        key: 'billedDate',
        label: 'Billed Date',
        type: 'Date',
        disabled: true,
        order: 20,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getCustomerInfo() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'firstName',
        label: 'First Name',
        order: 1,
      }),

      new TextboxField({
        key: 'lastName',
        label: 'Last Name',
        order: 2,
      }),

      new TextboxField({
        key: 'phoneNumber1',
        label: 'Primary Number',
        order: 3,
      }),

      new TextboxField({
        key: 'phoneNumber2',
        label: 'Secondary Number',
        order: 4,
      }),
      new TextboxField({
        key: 'email',
        label: 'Email Address',
        order: 5,
      }),
      new TextboxField({
        key: 'guardian',
        label: 'Guardian',
        order: 6,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getContractCustomerInfo() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'customerFirstName',
        label: 'First Name',
        required: true,
        order: 1,
      }),

      new TextboxField({
        key: 'customerLastName',
        label: 'Last Name',
        required: true,
        order: 2,
      }),

      new TextboxField({
        key: 'customerPhone',
        label: 'Primary Number',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        required: true,
        order: 3,
      }),

      new DropdownField({
        key: 'phoneTypeCode1',
        label: 'Primary Number Type',
        isSingleselect: true,
        options: [null, { key: 'Mobile', value: 'Mobile' }, { key: 'Landline', value: 'Landline' }],
        required: true,
        order: 4,
      }),

      new TextboxField({
        key: 'phoneNumber2',
        label: 'Secondary Number',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 5,
      }),
      new DropdownField({
        key: 'phoneTypeCode2',
        label: 'Secondary Number Type',
        isSingleselect: true,
        options: [null, { key: 'Mobile', value: 'Mobile' }, { key: 'Landline', value: 'Landline' }],
        order: 6,
      }),
      new TextboxField({
        key: 'customerEmail',
        label: 'Email Address',
        order: 7,
      }),
      new TextboxField({
        key: 'guardian',
        label: 'Guardian',
        order: 8,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getGeneralFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'general1',
        label: '50Back Status',
        order: 1,
      }),

      new TextboxField({
        key: 'general2',
        label: 'General2',
        order: 2,
      }),

      new TextboxField({
        key: 'general3',
        label: 'General3',
        order: 3,
      }),

      new TextboxField({
        key: 'general4',
        label: 'General4',
        order: 4,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getAdditionalInfoFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'masterBuyingGroup',
        label: 'Master Buying Group',
        order: 1,
      }),
      new TextboxField({
        key: 'agentName',
        label: 'Buying Group',
        order: 2,
      }),
      new TextboxField({
        key: 'dealerGroupName',
        label: 'Dealer Group Name',
        order: 3,
      }),

      new TextboxField({
        key: 'dealerGroupNumber',
        label: 'Dealer Group Number',
        order: 4,
      }),
      new TextboxField({
        key: 'storeLocationName',
        label: 'Selling Retailer Name',
        order: 5,
      }),
      new TextboxField({
        key: 'storeLocationNumber',
        label: 'Selling Retailer Code',
        order: 6,
      }),
      new TextboxField({
        key: 'phoneNumber1',
        label: 'Selling Retailer Phone Number',
        disabled: true,
        order: 7,
      }),
      new TextboxField({
        key: 'tSelfServicing',
        label: 'Self Servicing Dealer',
        disabled: true,
        order: 8,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getWorkQueueFields() {
    const optionsWorkQueueList = this.dropdownDataService.workQueueListTypes.map((x) => ({
      key: x.key,
      value: x.value,
    }));

    const userSpecificDealers = copy(this.azureService.dealerNumberLink);
    const dealerData = copy(this.dropdownDataService.dealerGroupData);
    let finalDealerData = [];
    if (userSpecificDealers && userSpecificDealers.length > 0) {
      userSpecificDealers.forEach((element) => {
        const dealer = dealerData.find((el) => el.dealerGroupNumber === element);
        finalDealerData.push(dealer);
      });
    } else {
      finalDealerData = dealerData;
    }

    const optionsDealerGroup = finalDealerData.map((x) => ({
      key: x.dealerGroupName,
      value: x.dealerGroupsIdOriginal,
    }));

    const optionsBuyingGroup = this.dropdownDataService.buyingGroupData
      ? this.dropdownDataService.buyingGroupData.map((x) => ({
          key: x.name,
          value: x.agentIdOriginal,
        }))
      : [];

    const optionsUserList = this.dropdownDataService.userListData
      ? this.dropdownDataService.userListData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];
    const optionsServicerList = this.dropdownDataService.servicerListData
      ? this.dropdownDataService.servicerListData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];

    let formFields: FormField<string>[] = [
      new DropdownField({
        key: 'queueType',
        label: 'Queue Type',
        isSingleselect: true,
        value: { key: 'NLF Master Queue', value: 1 },
        options: optionsWorkQueueList,
        order: 1,
      }),
      new DropdownField({
        key: 'createdBy',
        label: 'Created By',
        options: optionsUserList,
        filter: true,
        isSingleselect: true,
        order: 2,
      }),
      new DropdownField({
        key: 'buyingGroup',
        label: 'Buying Group',
        options: optionsBuyingGroup,
        filter: true,
        isSingleselect: true,
        order: 3,
      }),
      new AutocompleteField({
        key: 'servicer',
        label: 'Servicer',
        suggestions: [...optionsServicerList],
        keyword: 'key',
        order: 4,
      }),
      new DropdownField({
        key: 'dealerGroupNumber',
        label: 'Dealer',
        options: optionsDealerGroup,
        isSingleselect: true,
        filter: true,
        order: 4,
      }),
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: this.claimsLookupService.claimsLookup,
        isSingleselect: true,
        filter: true,
        order: 6,
      }),
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.OptionList,
      component: ContractFormFieldService,
      generalArray: formFields,
    };
    formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getContractsFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        value: { key: '', value: '' },
        options: this.getContractStatus(),
        isSingleselect: true,
        order: 1,
      }),

      new TextboxField({
        key: 'contractNumber',
        label: 'Contract#',
        order: 2,
      }),

      new TextboxField({
        key: 'uniqueId',
        label: 'Unique ID',
        order: 3,
      }),

      new TextboxField({
        key: 'firstName',
        label: 'First Name',
        order: 4,
      }),

      new TextboxField({
        key: 'lastName',
        label: 'Last Name',
        order: 5,
      }),
      new TextboxField({
        key: 'phoneNumber',
        label: 'Phone Number',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 6,
      }),
      new TextboxField({
        key: 'modelNumber',
        label: 'Model',
        order: 7,
      }),
      new TextboxField({
        key: 'serialNumber',
        label: 'Serial Number',
        order: 8,
      }),
      new TextboxField({
        key: 'externalId',
        label: 'Sales Receipt',
        order: 9,
      }),
      new TextboxField({
        key: 'address1',
        label: 'Address',
        order: 10,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getContractStatus() {
    const items = [
      { key: '', value: '' },
      { key: 'Active', value: 'Active' },
      { key: 'Cancelled', value: 'Cancelled' },
      { key: 'Expired', value: 'Expired' },
      { key: 'Pending', value: 'Pending' },
      { key: 'Suspended', value: 'Suspended' },
      { key: 'Unprocessed', value: 'Unprocessed' },
      { key: 'Suspended-Cancelled', value: 'Suspended-Cancelled' },
      {
        key: 'Void',
        value: 'Void',
        hasPermissions: true,
        permissions: {
          category: 'Contract',
          operation: 'READ_ONLY',
          permission: 'Void Contracts',
          type: 'Search-Item',
        },
      },
    ];
    return this.roleService.validateFormFields(items);
  }

  getAdvancedContractsFields() {
    const optionsSubcategoryDescription = this.dropdownDataService.subCategoryDescriptionList
      ? this.dropdownDataService.subCategoryDescriptionList.map((x) => ({
          key: x.value,
          value: x.key,
        }))
      : [];

    const optionsDealerGroup = this.dropdownDataService.dealerGroupData
      ? this.dropdownDataService.dealerGroupData.map((x) => ({
          key: x.dealerGroupName,
          value: x.dealerGroupsIdOriginal,
        }))
      : [];

    const optionsState = this.dropdownDataService.stateList
      ? this.dropdownDataService.stateList.map((x) => ({
          key: x.stateName,
          value: x.stateCode,
        }))
      : [];

    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        value: { key: '', value: '' },
        options: [
          { key: '', value: '' },
          { key: 'Active', value: 'Active' },
          { key: 'Cancelled', value: 'Cancelled' },
          { key: 'Expired', value: 'Expired' },
          { key: 'Pending', value: 'Pending' },
          { key: 'Suspended', value: 'Suspended' },
          { key: 'Void', value: 'Void' },
          { key: 'Suspended-Cancelled', value: 'Suspended-Cancelled' },
        ],
        isSingleselect: true,
        order: 1,
      }),

      new TextboxField({
        key: 'contractNumber',
        label: 'Contract#',
        order: 2,
      }),

      new TextboxField({
        key: 'uniqueId',
        label: 'Unique ID',
        order: 3,
      }),

      new TextboxField({
        key: 'firstName',
        label: 'First Name',
        order: 4,
      }),

      new TextboxField({
        key: 'lastName',
        label: 'Last Name',
        order: 5,
      }),

      new TextboxField({
        key: 'phoneNumber',
        label: 'Primary Phone Number',
        type: 'number',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 6,
      }),

      new TextboxField({
        key: 'guardian',
        label: 'Guardian',
        order: 7,
      }),

      new TextboxField({
        key: 'city',
        label: 'City',
        order: 8,
      }),

      new TextboxField({
        key: 'address1',
        label: 'Address',
        order: 9,
      }),

      new TextboxField({
        key: 'externalId',
        label: 'Sales Receipt/REF#',
        order: 10,
      }),

      new DropdownField({
        key: 'state',
        label: 'State',
        options: [{ key: '', value: '' }, ...optionsState],
        filter: true,
        isSingleselect: true,
        order: 11,
      }),

      new TextboxField({
        key: 'manufacturer',
        label: 'MFG ',
        order: 12,
      }),

      new TextboxField({
        key: 'modelNumber',
        label: 'Model#',
        order: 13,
      }),

      new TextboxField({
        key: 'serialNumber',
        label: 'Serial#',
        order: 14,
      }),

      new DropdownField({
        key: 'subcategory',
        label: 'Subcategory Description',
        options: optionsSubcategoryDescription,
        filter: true,
        isSingleselect: true,
        order: 15,
      }),

      new DropdownField({
        key: 'dealer',
        label: 'Dealer',
        options: optionsDealerGroup,
        isSingleselect: true,
        filter: true,
        order: 16,
      }),

      new TextboxField({
        key: 'transactionDate',
        label: 'New Leaf Transaction Date',
        type: 'Date',
        isPDate: true,
        selectionMode: 'range',
        order: 17,
      }),

      new TextboxField({
        key: 'effectiveDate',
        label: 'Plan Effective Date',
        type: 'Date',
        isPDate: true,
        selectionMode: 'range',
        order: 18,
      }),

      new TextboxField({
        key: 'entryDate',
        label: 'Entry Date',
        type: 'Date',
        isPDate: true,
        selectionMode: 'range',
        order: 19,
      }),

      new TextboxField({
        key: 'productPurchaseDate',
        label: 'Product Purchase Date',
        type: 'Date',
        isPDate: true,
        selectionMode: 'range',
        order: 20,
      }),

      // Uncomment when this item is searchable
      // new TextboxField({
      //   key: 'coverageName',
      //   label: 'Coverage Name',
      //   order: 18
      // })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getNameFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'firstName',
        label: 'First Name',
        order: 1,
      }),

      new TextboxField({
        key: 'lastName',
        label: 'Last Name',
        order: 2,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getUserContactFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'zipCode',
        label: 'Zip Code',
        order: 3,
      }),

      new TextboxField({
        key: 'phone',
        label: 'Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 4,
      }),

      new TextboxField({
        key: 'receiptNumber',
        label: 'Receipt Number',
        order: 5,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getUsernameFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'createUserName',
        label: 'Create User Name',
        order: 6,
      }),

      new TextboxField({
        key: 'repeateUserName',
        label: 'Repeat User Name',
        order: 7,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getCardFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'activation_code',
        label: 'Activation Code',
        order: 1,
      }),

      new TextboxField({
        key: 'service_plan_sku',
        label: 'Service Plan SKU',
        order: 2,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getCustomerInformationFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'first_name',
        label: 'First Name',
        order: 1,
      }),

      new TextboxField({
        key: 'last_name',
        label: 'Last Name',
        order: 2,
      }),

      new TextboxField({
        key: 'phone',
        label: 'Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 3,
      }),

      new TextboxField({
        key: 'customer_email',
        label: 'Customer Email',
        order: 4,
      }),

      new TextboxField({
        key: 'confirm_email',
        label: 'Confirm Email',
        order: 5,
      }),

      new TextboxField({
        key: 'address_line_1',
        label: 'Address Line 1',
        order: 6,
      }),

      new TextboxField({
        key: 'address_line_2',
        label: 'Address Line 2',
        order: 7,
      }),

      new TextboxField({
        key: 'city',
        label: 'City',
        order: 8,
      }),

      new TextboxField({
        key: 'state',
        label: 'State',
        order: 9,
      }),

      new TextboxField({
        key: 'zip_code',
        label: 'Zip Code',
        validationRegex: ZipCodePlus4Pattern,
        validationMessage: 'Please enter a valid zip code with 5 digits.',
        order: 10,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getServicePlanFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'origin_plan',
        label: 'Where did you purchase Service Plan?',
        isSingleselect: true,
        options: [
          { key: 'Internet', value: 'internet' },
          { key: 'Local Store', value: 'local_store' },
          { key: 'Online', value: 'online' },
        ],
        order: 1,
      }),

      new TextboxField({
        key: 'retail_price_paid',
        label: 'Retail Price Paid for Plan (less tax)',
        order: 2,
      }),

      new TextboxField({
        key: 'date_purchase',
        label: 'Date of Service Plan purchase',
        type: 'Date',
        order: 3,
      }),

      new TextboxField({
        key: 'sales_receipt',
        label: 'Sales Receipt/Invoice number',
        order: 4,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getProductCoveredFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'retail_product_price_paid',
        label: 'Retail Price Paid for Plan (less tax)',
        order: 1,
      }),

      new TextboxField({
        key: 'date_service_purchase',
        label: 'Date of Service Plan purchase',
        type: 'Date',
        order: 2,
      }),

      new DropdownField({
        key: 'type_product_bought',
        label: 'What type of Product did you buy?',
        isSingleselect: true,
        options: [
          { key: 'Refrigerator', value: 'refrigerator' },
          { key: 'Washing Machine', value: 'washing_machine' },
          { key: 'Computer', value: 'computer' },
        ],
        order: 3,
      }),

      new DropdownField({
        key: 'manufacturer',
        label: 'Manufacturer',
        isSingleselect: true,
        options: [
          { key: 'LG', value: 'lg' },
          { key: 'Samsung', value: 'samsung' },
          { key: 'DELL', value: 'dell' },
        ],
        order: 4,
      }),

      new TextboxField({
        key: 'model',
        label: 'Model',
        order: 5,
      }),

      new TextboxField({
        key: 'serial_number',
        label: 'Serial Number',
        order: 6,
      }),

      new DropdownField({
        key: 'product_state',
        label: 'Is the Product New, Used or Refurbished',
        isSingleselect: true,
        options: [
          { key: 'New', value: 'new' },
          { key: 'Used', value: 'used' },
          { key: 'Refurbished', value: 'refurbished' },
        ],
        order: 7,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getInquiryQueueFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'groupname',
        label: 'Group Name',
        order: 1,
      }),

      new TextboxField({
        key: 'username',
        label: 'User Name',
        order: 2,
      }),

      new TextboxField({
        key: 'email',
        label: 'Email',
        order: 3,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getBatchProcessingFields() {
    const statusOption = [
      {
        key: 'UNPROCESSED',
        value: 'UNPROCESSED',
        hasPermissionsSearch: true,
        permissionSearch: {
          type: 'dpElement',
          operation: 'READ_ONLY',
          permission: 'Unprocessed Contracts',
          category: 'User Security',
        },
      },
      { key: 'COMPLETE', value: 'COMPLETE' },
      { key: 'VOIDED', value: 'VOIDED' },
      { key: 'PARTIAL', value: 'PARTIAL' },
      { key: 'PROCESSING', value: 'PROCESSING' },
    ];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'fileStatus',
        label: 'Status',
        options: this.roleService.validateSearch(statusOption),
        isSingleselect: true,
        order: 1,
      }),
      new TextboxField({
        key: 'fileType',
        label: 'File Type',
        order: 2,
      }),

      new TextboxField({
        key: 'dealerName',
        label: 'Dealer Name',
        order: 3,
      }),

      new TextboxField({
        key: 'fileName',
        label: 'File Name',
        order: 4,
      }),

      new TextboxField({
        key: 'uploadDateFrom',
        label: 'Upload Date',
        type: 'Date',
        order: 5,
      }),
      new TextboxField({
        key: 'uploadDateTo',
        type: 'Date',
        order: 5,
      }),
      new TextboxField({
        key: 'planCount',
        label: 'Plan Count',
        order: 6,
      }),
      new TextboxField({
        key: 'contractRange',
        label: 'Contract# Range',
        order: 7,
      }),

      new TextboxField({
        key: 'dealerCost',
        label: 'Dealer Cost',
        order: 8,
      }),
      new TextboxField({
        key: 'nlfTransactionDateFrom',
        label: 'NLF Transaction Date',
        type: 'Date',
        order: 9,
      }),
      new TextboxField({
        key: 'nlfTransactionDateTo',
        type: 'Date',
        order: 9,
      }),

      new TextboxField({
        key: 'cancellationTransactionDateFrom',
        label: 'Cancellation Transaction Date',
        type: 'Date',
        order: 10,
      }),
      new TextboxField({
        key: 'cancellationTransactionDateTo',
        type: 'Date',
        order: 10,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getTransactionManagementFields() {
    const optionsTransactionYear = this.dropdownDataService.transactionYear.map((x) => ({
      key: x.key,
      value: x.value,
    }));
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'year',
        label: 'Year',
        isSingleselect: true,
        options: optionsTransactionYear,
        filter: true,
        order: 1,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getSubmitProviderFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'insurance company',
        label: 'Insurance Company',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
      new TextboxField({
        key: 'dealerName',
        label: 'Dealer Name',
        order: 2,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getServiceOrderFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        filter: true,
        isSingleselect: true,
        options: this.claimsLookupService.claimsLookup,
        order: 1,
      }),
      new TextboxField({
        key: 'claimNumber',
        label: 'Claim Number/Service Order',
        order: 2,
      }),

      new TextboxField({
        key: 'contractNumber',
        label: 'Contract Number',
        order: 3,
      }),

      new TextboxField({
        key: 'uniqueId',
        label: 'Unique ID',
        order: 4,
      }),

      new TextboxField({
        key: 'invoiceNumber',
        label: 'Invoice Number',
        order: 5,
      }),
      new TextboxField({
        key: 'phoneNumber',
        label: 'Phone Number',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 6,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getServicerFields() {
    const optionsState = this.dropdownDataService.stateList
      ? this.dropdownDataService.stateList.map((x) => ({
          key: x.stateName,
          value: x.stateCode,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: '', value: '' },
          { key: 'Active', value: 'Active' },
          { key: 'Inactive', value: 'Inactive' },
          { key: 'Suspended', value: 'Suspended' },
        ],
        isSingleselect: true,
        order: 1,
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        order: 2,
      }),
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 3,
      }),
      new TextboxField({
        key: 'city',
        label: 'City',
        order: 4,
      }),
      new DropdownField({
        key: 'state',
        label: 'State',
        options: [{ key: '', value: '' }, ...optionsState],
        filter: true,
        isSingleselect: true,
        order: 5,
      }),
      new TextboxField({
        key: 'zipCode',
        label: 'Zip',
        order: 6,
      }),
      new TextboxField({
        key: 'phoneNumber',
        label: 'Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 7,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getServiceCenterLookupFields() {
    const optionsCategory = this.dropdownDataService.categoryDescriptionList
      ? this.dropdownDataService.categoryDescriptionList.map((x) => ({
          key: x,
          value: x,
        }))
      : [];
    const optionsSubCategory = this.dropdownDataService.subCategoryDescriptionList
      ? this.dropdownDataService.subCategoryDescriptionList.map((x) => ({
          key: x.value,
          value: x.value,
        }))
      : [];
    const optionsTier = this.dropdownDataService.tierDescriptionData
      ? this.dropdownDataService.tierDescriptionData.map((x) => ({
          key: x.value,
          value: x.key,
        }))
      : [];

    const optionsState = this.dropdownDataService.stateList
      ? this.dropdownDataService.stateList.map((x) => ({
          key: x.stateName,
          value: x.stateCode,
        }))
      : [];
    const optionsManufacturer = (this.dropdownDataService.manufacturerList = this
      .dropdownDataService.manufacturerDetailList
      ? this.dropdownDataService.manufacturerDetailList.map((x) => ({
          key: x.manufacturersName,
          value: x.manufacturersName,
        }))
      : []);
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'service_type',
        label: 'Service Type',
        options: [
          { key: '', value: '' },
          { key: 'Service', value: 'Service' },
          { key: 'Other', value: 'Other' },
          { key: 'Replacement/Store Credit', value: 'Replacement/Store Credit' },
          { key: 'Parts Vendor', value: 'Parts Vendor' },
        ],
        isSingleselect: true,
        order: 1,
      }),

      new DropdownField({
        key: 'category',
        label: 'Category',
        options: [{ key: 'ALL', value: 'ALL' }, ...optionsCategory],
        filter: true,
        order: 2,
      }),

      new DropdownField({
        key: 'tier',
        label: 'Tier',
        options: [{ key: 'ALL', value: 'ALL' }, ...optionsTier],
        filter: true,
        order: 3,
      }),

      new DropdownField({
        key: 'subcategory',
        label: 'Subcategory',
        options: [{ key: 'ALL', value: 'ALL' }, ...optionsSubCategory],
        filter: true,
        order: 4,
      }),

      new DropdownField({
        key: 'contract',
        label: 'Contracted',
        options: [
          { key: '', value: '' },
          { key: 'Yes', value: 'Yes' },
          { key: 'No', value: 'No' },
        ],
        isSingleselect: true,
        order: 5,
      }),

      new DropdownField({
        key: 'manufacturer',
        label: 'Manufacturer',
        options: [{ key: 'ALL', value: 'ALL' }, ...optionsManufacturer],
        filter: true,
        order: 6,
      }),
      new TextboxField({
        key: 'zip',
        label: 'ZIP',
        order: 7,
      }),
      new TextboxField({
        key: 'city',
        label: 'City',
        order: 8,
      }),
      new DropdownField({
        key: 'state',
        label: 'State',
        options: [{ key: '', value: '' }, ...optionsState],
        filter: true,
        isSingleselect: true,
        order: 9,
      }),

      new TextboxField({
        key: 'radius',
        label: 'Radius',
        order: 10,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getClaimAdjudicationToolFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),

      new TextboxField({
        key: 'question',
        label: 'Question',
        order: 2,
      }),

      new TextboxField({
        key: 'answer',
        label: 'Answer',
        order: 3,
      }),

      new TextboxField({
        key: 'coverage name',
        label: 'Coverage Name',
        order: 4,
      }),

      new TextboxField({
        key: 'subcategory',
        label: 'Subcategory',
        order: 5,
      }),
      new TextboxField({
        key: 'category',
        label: 'Category',
        order: 6,
      }),
      new TextboxField({
        key: 'manufacturer',
        label: 'Manufacturer',
        order: 7,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getClaimPaymentNotificationFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        isSingleselect: true,
        options: [{ key: 'OPEN', value: 'OPEN' }],
        order: 1,
      }),

      new TextboxField({
        key: 'fileName',
        label: 'File Name',
        order: 2,
      }),

      new TextboxField({
        key: 'uploadedDate',
        label: 'Uploaded Date',
        type: 'Date',
        order: 3,
      }),

      new TextboxField({
        key: 'remittanceDate',
        label: 'Remittance Date',
        type: 'Date',
        order: 4,
      }),

      new TextboxField({
        key: 'claimCount',
        label: 'Claim Count',
        order: 5,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getDealerInfoFields() {
    const optionsState = this.dropdownDataService.stateList
      ? this.dropdownDataService.stateList.map((x) => ({
          key: x.stateName,
          value: x.stateCode,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'Active', value: 'A' },
          { key: 'Pending', value: 'P' },
          { key: 'Terminated', value: 'T' },
          { key: 'Cancelled', value: 'C' },
        ],
        isSingleselect: true,
        order: 1,
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        order: 2,
      }),
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 3,
      }),
      new TextboxField({
        key: 'city',
        label: 'City',
        order: 4,
      }),

      new DropdownField({
        key: 'state',
        label: 'State',
        options: [{ key: '', value: '' }, ...optionsState],
        filter: true,
        isSingleselect: true,
        order: 5,
      }),
      new TextboxField({
        key: 'zipCode',
        label: 'Zip',
        order: 6,
      }),
      new TextboxField({
        key: 'phoneNumber',
        label: 'Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',

        order: 7,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getDealerFields() {
    const optionsState = this.dropdownDataService.stateList
      ? this.dropdownDataService.stateList.map((x) => ({
          key: x.stateName,
          value: x.stateCode,
        }))
      : [];
    let formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'Active', value: 'Active' },
          { key: 'Pending', value: 'Pending' },
          { key: 'Terminated', value: 'Terminated' },
        ],
        isSingleselect: true,
        order: 1,
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        order: 2,
      }),
      new TextboxField({
        key: 'name',
        label: 'Dealer',
        order: 3,
      }),
      new DropdownField({
        key: 'buyingGroup',
        label: 'Buying Group',
        options: [],
        filter: true,
        isSingleselect: true,
        order: 4,
      }),

      new TextboxField({
        key: 'city',
        label: 'City',
        order: 5,
      }),

      new DropdownField({
        key: 'state',
        label: 'State',
        options: [{ key: '', value: '' }, ...optionsState],
        filter: true,
        isSingleselect: true,
        order: 6,
      }),
      new TextboxField({
        key: 'zipCode',
        label: 'Zip',
        order: 7,
      }),
      new TextboxField({
        key: 'phoneNumber',
        label: 'Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 8,
      }),
    ];
    let buyingGroupDropdown = formFields.find((x) => x.key === 'buyingGroup');
    const optionsBuyingGroup = this.dropdownDataService.buyingGroupData
      ? this.dropdownDataService.buyingGroupData.map((x) => ({
          key: x.name,
          value: x.agentIdOriginal,
        }))
      : [];
    buyingGroupDropdown = { ...buyingGroupDropdown, options: [...optionsBuyingGroup] };
    formFields = [
      ...formFields.filter((x) => x.key !== 'buyingGroup'),
      new DropdownField(buyingGroupDropdown),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getRateProfileFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'Name',
        label: 'Name',
        order: 1,
      }),
      new DropdownField({
        key: 'product Types',
        label: 'Product Types',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 2,
      }),
      new DropdownField({
        key: 'active',
        label: 'Active',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 3,
      }),
      new DropdownField({
        key: 'masterProfile',
        label: 'Master Profile',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 4,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getPayeeFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'Active', value: 'Active' },
          { key: 'Pending', value: 'Pending' },
          { key: 'Terminated', value: 'Terminated' },
        ],
        isSingleselect: true,
        order: 1,
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        order: 2,
      }),
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 3,
      }),
      new TextboxField({
        key: 'city',
        label: 'City',
        order: 4,
      }),
      new TextboxField({
        key: 'state',
        label: 'State',
        order: 5,
      }),

      new TextboxField({
        key: 'phoneNumber',
        label: 'Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 6,
      }),
      new TextboxField({
        key: 'associationType',
        label: 'Association Type',
        order: 7,
      }),
      new TextboxField({
        key: 'associationName',
        label: 'Association Name',
        order: 8,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getSetupAlertsFields() {
    const optionsManufacturerList = this.dropdownDataService.manufacturerDetailList
      ? this.dropdownDataService.manufacturerDetailList.map((x) => ({
          key: x.manufacturersName,
          value: x.manufacturersName,
        }))
      : [];
    const productPlanTypeList = this.dropdownDataService.productPlanTypeList
      ? this.dropdownDataService.productPlanTypeList.map((x) => ({
          key: x.value,
          value: x.value,
        }))
      : [];
    const moduleTypeList = this.dropdownDataService.moduleTypeList
      ? this.dropdownDataService.moduleTypeList.map((x) => ({
          key: x.value,
          value: x.value,
        }))
      : [];
    const optionsDealerList = this.dropdownDataService.dealerGroupData
      ? this.dropdownDataService.dealerGroupData.map((x) => ({
          key: x.dealerGroupName,
          value: x.dealerGroupsIdOriginal,
        }))
      : [];
    const optionsCategoryDescriptionList = this.dropdownDataService.categoryList
      ? this.dropdownDataService.categoryList.map((x) => ({
          key: x.value,
          value: x.value,
        }))
      : [];
    const optionsSubCategory = this.dropdownDataService.subCategoryDescriptionList
      ? this.dropdownDataService.subCategoryDescriptionList.map((x) => ({
          key: x.value,
          value: x.value,
        }))
      : [];
    const optionsDealerLocationList = this.dropdownDataService.dealerLocationList
      ? this.dropdownDataService.dealerLocationList.map((x) => ({
          key: x.value,
          value: x.value,
        }))
      : [];
    const optionsBuyingGroupDescriptionList = this.dropdownDataService.buyingGroupData
      ? this.dropdownDataService.buyingGroupData.map((x) => ({
          key: x.name,
          value: x.name,
        }))
      : [];
    // const optionsSubCategoryDescriptionList = this.dropdownDataService.subCategoryDescriptionList;
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'alertStatus',
        label: 'STATUS',
        isSingleselect: true,
        options: [
          { key: 'All', value: 'All' },
          { key: 'Active', value: 'Active' },
          { key: 'Inactive', value: 'Inactive' },
        ],
        order: 0,
      }),
      new TextboxField({
        key: 'alertMessage',
        label: 'MESSAGE',
        order: 1,
      }),
      new DropdownField({
        key: 'moduleType',
        label: 'MODULE',
        options: [{ key: 'All', value: 'All' }, ...moduleTypeList],
        isSingleselect: true,
        virtualScroll: true,
        filter: true,
        order: 2,
      }),
      new DropdownField({
        key: 'type',
        label: 'PRODUCT TYPE',
        isSingleselect: true,
        options: [{ key: 'All', value: 'All' }, ...productPlanTypeList],
        order: 3,
      }),
      new DropdownField({
        key: 'manufacturerName',
        label: 'MANUFACTURER',
        options: [{ key: 'All', value: 'All' }, ...optionsManufacturerList],
        isSingleselect: true,
        virtualScroll: true,
        filter: true,
        order: 4,
      }),
      new DropdownField({
        key: 'categoryDescription',
        label: 'CATEGORY',
        options: [{ key: 'All', value: 'All' }, ...optionsCategoryDescriptionList],
        isSingleselect: true,
        virtualScroll: true,
        filter: true,
        order: 5,
      }),
      new DropdownField({
        key: 'subcategoryDescription',
        label: 'SUB-CATEGORY',
        isSingleselect: true,
        options: [{ key: 'All', value: 'All' }, ...optionsSubCategory],
        virtualScroll: true,
        showToggleAll: false,
        filter: true,
        order: 6,
      }),
      new DropdownField({
        key: 'dealers',
        label: 'DEALERS',
        isSingleselect: true,
        options: [{ key: 'All', value: 'All' }, ...optionsDealerList],
        virtualScroll: true,
        showToggleAll: false,
        filter: true,
        order: 7,
      }),
      new DropdownField({
        key: 'dealerLocations',
        label: 'DEALER LOCATIONS',
        isSingleselect: true,
        options: [{ key: 'All', value: 'All' }, ...optionsDealerLocationList],
        virtualScroll: true,
        showToggleAll: false,
        filter: true,
        order: 8,
      }),
      new DropdownField({
        key: 'buyingGroup',
        label: 'BUYING GROUP',
        isSingleselect: true,
        options: [{ key: 'All', value: 'All' }, ...optionsBuyingGroupDescriptionList],
        virtualScroll: true,
        showToggleAll: false,
        filter: true,
        order: 9,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getBuyingGroupFields() {
    const optionsBuyingGroup = this.dropdownDataService.masterBuyingGroupData
      ? this.dropdownDataService.masterBuyingGroupData.map((x) => ({
          key: x.name,
          value: x.masterBuyingGroupIDOriginal,
        }))
      : [];
    const optionsState = this.dropdownDataService.stateList
      ? this.dropdownDataService.stateList.map((x) => ({
          key: x.stateName,
          value: x.stateCode,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'masterBuyingGroupId',
        label: 'Master Buying Group',
        options: optionsBuyingGroup,
        isSingleselect: true,
        order: 2,
      }),
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'Active', value: 'A' },
          { key: 'Cancelled', value: 'C' },
          { key: 'Expired ', value: 'E ' },
          { key: 'Pending', value: 'P' },
        ],
        isSingleselect: true,
        order: 3,
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        order: 4,
      }),
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 5,
      }),
      new TextboxField({
        key: 'city',
        label: 'City',
        order: 6,
      }),
      new DropdownField({
        key: 'state',
        label: 'State',
        options: [{ key: '', value: '' }, ...optionsState],
        filter: true,
        isSingleselect: true,
        order: 7,
      }),

      new TextboxField({
        key: 'zipCode',
        label: 'Zip',
        order: 8,
      }),
      new TextboxField({
        key: 'phoneNumber',
        label: 'Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 9,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getFailureTypeFields() {
    const optionTier = this.dropdownDataService.tierDescriptionData
      ? this.dropdownDataService.tierDescriptionData.map((x) => ({
          key: x.value,
          value: x.key,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'tier',
        label: 'Tier',
        options: optionTier,
        filter: true,
        isSingleselect: true,
        order: 1,
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 2,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getResolutionsFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'tier',
        label: 'Tier',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
      new TextboxField({
        key: 'symptoms',
        label: 'Symptom',
        order: 2,
      }),
      new TextboxField({
        key: 'resolution',
        label: 'Resolution',
        order: 3,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getQueueFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'reviewed',
        label: 'Reviewed',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 2,
      }),
      new DropdownField({
        key: 'category',
        label: 'Category',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 3,
      }),
      new DropdownField({
        key: 'tier',
        label: 'Tier',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 4,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getCoverageFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'code',
        label: 'Code',
        order: 1,
      }),
      new TextboxField({
        key: 'skuCode',
        label: 'SKU Description',
        order: 2,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getComponentsFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'product Type',
        label: 'Product Type',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 2,
      }),
      new DropdownField({
        key: 'subcategory',
        label: 'Sub Cateory',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 3,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getLossCodesFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'component',
        label: 'Component',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
      new TextboxField({
        key: 'code',
        label: 'Code',
        order: 2,
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 3,
      }),
      new DropdownField({
        key: 'type',
        label: 'Type',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 4,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getPriceTableFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 1,
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 2,
      }),
      new DropdownField({
        key: 'active',
        label: 'Active',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 3,
      }),
      new DropdownField({
        key: 'default profile',
        label: 'Default Profile',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 4,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getRateBucketConfigurationFields() {
    const formFields: FormField<string>[] = [];

    return of(formFields);
  }
  getCancellationMethodsFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 1,
      }),
      new TextboxField({
        key: 'code',
        label: 'Code',
        order: 2,
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 3,
      }),
      new DropdownField({
        key: 'is active',
        label: 'IsActive',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 4,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getCancellationRulesFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'rule name',
        label: 'Rule Name',
        order: 1,
      }),
      new DropdownField({
        key: 'product type',
        label: 'Product Type',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 2,
      }),
      new TextboxField({
        key: 'sale from',
        label: 'Sale From',
        type: 'Date',
        order: 3,
      }),
      new TextboxField({
        type: 'Date',
        order: 3,
      }),
      new TextboxField({
        key: 'sale to',
        label: 'Sale To',
        type: 'Date',
        order: 4,
      }),
      new TextboxField({
        type: 'Date',
        order: 4,
      }),
      new DropdownField({
        key: 'default rule',
        label: 'Deafult Rule',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 4,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getCoverageGroupsFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'group type',
        label: 'Group Type',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
      new TextboxField({
        key: 'code',
        label: 'Code',
        order: 2,
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 3,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getCoverageUploadsFields() {
    const formFields: FormField<string>[] = [];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getProductTypesFields() {
    const formFields: FormField<string>[] = [];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getInsuranceCompanyFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'Active', value: 'A' },
          { key: 'Cancelled', value: 'C' },
          { key: 'Expired ', value: 'E ' },
          { key: 'Pending', value: 'P' },
        ],
        isSingleselect: true,
        order: 1,
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        order: 2,
      }),
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 3,
      }),
      new TextboxField({
        key: 'city',
        label: 'City',
        order: 4,
      }),
      new TextboxField({
        key: 'state',
        label: 'State',
        order: 5,
      }),
      new TextboxField({
        key: 'phoneNumber',
        label: 'Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 6,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getOEMFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'fileName',
        label: 'Imported File Name',
        order: 1,
      }),
      new TextboxField({
        key: 'uploadedDate',
        label: 'Date/Time Stamp',
        type: 'Date',
        order: 3,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getManufacturerFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'name',
        label: 'Name (LIKE)',
        order: 1,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getPositionFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'name (like)',
        label: 'Name (LIKE)',
        order: 1,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getCategoryFields() {
    const categoryDescriptionList = this.dropdownDataService.categoryDescriptionList
      ? this.dropdownDataService.categoryDescriptionList.map((x, index) => ({
          key: x,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'tierCode',
        label: 'Tier Code',
        order: 1,
      }),
      new TextboxField({
        key: 'tierDescription',
        label: 'Tier Description',
        order: 2,
      }),
      new DropdownField({
        key: 'category',
        label: 'Category',
        options: categoryDescriptionList,
        isSingleselect: false,
        order: 3,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getCategoryUpdateFields() {
    const categoryDescriptionList = this.dropdownDataService.categoryDescriptionList
      ? this.dropdownDataService.categoryDescriptionList.map((x, index) => ({
          key: x,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'tierCode',
        label: 'Tier Code',
        required: true,
        order: 1,
      }),
      new TextboxField({
        key: 'tierDescription',
        label: 'Tier Description',
        order: 2,
      }),
      new CheckBoxField({
        key: 'addNewCategory',
        options: [{ key: 'newCategory', value: 'Add New Parent Category' }],
        isChangeEvent: true,
        order: 3,
      }),
      new DropdownField({
        key: 'category',
        label: 'Category',
        options: categoryDescriptionList,
        required: true,
        isSingleselect: true,
        order: 4,
      }),
      new TextboxField({
        key: 'newCategory',
        label: 'Category',
        required: true,
        order: 4,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getSubCategoryFields() {
    const categoryDescriptionList = this.dropdownDataService.categoryDescriptionList
      ? this.dropdownDataService.categoryDescriptionList.map((x, index) => ({
          key: x,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'category',
        label: 'Category',
        options: categoryDescriptionList,
        order: 1,
      }),
      new TextboxField({
        key: 'code',
        label: 'Subcategory Code',
        order: 2,
      }),
      new TextboxField({
        key: 'description',
        label: 'Subcategory Description',
        order: 3,
      }),
      new TextboxField({
        key: 'tier',
        label: 'Tier Code',
        order: 4,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getSubCategoryUpdateFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'code',
        label: 'Subcategory Code',
        required: true,
        order: 1,
      }),
      new TextboxField({
        key: 'description',
        label: 'Subcategory Description',
        order: 2,
      }),
      new DropdownField({
        key: 'tier',
        label: 'Tier Code',
        isSingleselect: true,
        isChangeEvent: true,
        required: true,
        order: 3,
      }),
      new TextboxField({
        key: 'category',
        label: 'Category',
        disabled: true,
        order: 4,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getSaleRepresentativesFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'code (like)',
        label: 'Code (LIKE)',
        order: 1,
      }),
      new TextboxField({
        key: 'name (like)',
        label: 'Name (LIKE)',
        order: 2,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getPricePaidFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'category',
        label: 'Category',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
      new TextboxField({
        key: 'tier',
        label: 'Tier',
        order: 2,
      }),
      new TextboxField({
        key: 'price from',
        label: 'Price From',
        order: 3,
      }),
      new TextboxField({
        key: 'price to',
        label: 'Price To',
        order: 3,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getWorkQueueListFields() {
    const optionsWorkQueueList = this.dropdownDataService.workQueueListTypes.map((x) => ({
      key: x.key,
      value: x.value,
    }));

    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'queueType',
        label: 'Queue Type',
        options: optionsWorkQueueList,
        isSingleselect: true,
        filter: true,
        order: 1,
      }),
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: this.claimsLookupService.claimsLookup,
        isSingleselect: true,
        filter: true,
        order: 2,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getSearchMessageFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'active',
        label: 'Active',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
      new DropdownField({
        key: 'type',
        label: 'Type',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 2,
      }),
      new TextboxField({
        key: 'message code',
        label: 'Message Code',
        order: 3,
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 4,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getFormModalFields() {
    const formFields: FormField<string>[] = [
      new CheckBoxField({
        key: '',
        label: '',
        order: 1,
        type: 'checkbox',
        options: [{ key: 'Reviewed', value: 'Reviewed' }],
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 2,
      }),
      new TextboxField({
        key: 'failure',
        label: 'Failure',
        order: 3,
      }),
      new TextboxField({
        key: 'category',
        label: 'Category',
        order: 4,
      }),
      new TextboxField({
        key: 'tier',
        label: 'Tier',
        order: 5,
      }),
      new TextboxField({
        key: 'note',
        label: 'Note',
        order: 6,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getBuyingGroupDetailsFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        order: 2,
      }),
      new TextboxField({
        key: 'Fed Tax ID',
        label: 'Fed Tax ID',
        order: 3,
      }),
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 4,
      }),
      new DropdownField({
        key: 'buying group',
        label: 'Buying Group',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 5,
      }),
      new DropdownField({
        key: 'rate profile',
        label: 'Rates Profile',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 6,
      }),
      new TextboxField({
        key: 'contract date',
        label: 'Contract Date',
        type: 'Date',
        order: 7,
      }),
      new TextboxField({
        key: 'contract exp date',
        label: 'Contract Exp Date',
        type: 'Date',
        order: 8,
      }),
      new TextboxField({
        key: 'Effective based on',
        label: 'Effective Based On',
        order: 9,
      }),
      new DropdownField({
        key: 'price table',
        label: 'Price table',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 10,
      }),
      new TextboxField({
        key: 'Insurance Carrier Code',
        label: 'Insurance Carrier Code',
        order: 11,
      }),
      new TextboxField({
        key: 'Insurance Carrier Name',
        label: 'Insurance Carrier Name',
        order: 12,
      }),
      new CheckBoxField({
        key: 'masterbuyingGroup',
        label: '',
        order: 13,
        type: 'checkbox',
        options: [{ key: 'Master Buying Group', value: 'Master Buying Group' }],
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getContactInfoFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'Primary contact firstName',
        label: 'Primary Contact First Name',
        order: 1,
      }),

      new TextboxField({
        key: 'Primary contact lastName',
        label: 'Primary Contact Last Name',
        order: 2,
      }),

      new TextboxField({
        key: 'BusinessPhone',
        label: 'Business Phone',
        order: 3,
      }),

      new TextboxField({
        key: 'fax',
        label: 'Fax',
        order: 4,
      }),
      new TextboxField({
        key: 'email',
        label: 'Email Address',
        order: 5,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getMailingBillingAddressFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'country',
        label: 'Country',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
      new TextboxField({
        key: 'addressLine1',
        label: 'Address Line 1',
        order: 2,
      }),
      new TextboxField({
        key: 'addressLine2',
        label: 'Address Line 2',
        order: 3,
      }),
      new TextboxField({
        key: 'zip',
        label: 'Zip',
        validationRegex: ZipCodePlus4Pattern,
        validationMessage: 'Please enter a valid zip code with 5 digits.',
        order: 4,
      }),
      new TextboxField({
        key: 'state',
        label: 'State',
        order: 5,
      }),
      new TextboxField({
        key: 'city',
        label: 'City',
        order: 6,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getPositionInfoFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'position',
        label: 'Position',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getSecurityUsersFields() {
    const addSpace = /([A-Z])/g;
    const optionsProfileTypesList = this.dropdownDataService.userProfileTypesList
      ? this.dropdownDataService.userProfileTypesList.map((x) => ({
          key: x.userProfileType.replace(addSpace, ' $1'),
          value: x.userProfileType,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'username',
        label: 'User Name',
        order: 1,
      }),
      new TextboxField({
        key: 'email',
        label: 'Email',
        order: 2,
      }),
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        isSingleselect: true,
        order: 3,
      }),
      new DropdownField({
        key: 'attached_roles',
        label: 'Attached Roles',
        options: [
          { key: '!Service Provider Independent', value: '!Service Provider Independent' },
          { key: 'Account Manager', value: 'Account Manager' },
          { key: 'Accounting', value: 'Accounting' },
          { key: 'Accounting Manager', value: 'Accounting Manager' },
          { key: 'Admin', value: 'Admin' },
          { key: 'Back Office', value: 'Back Office' },
          { key: 'Back Office Lead', value: 'Back Office Lead' },
          { key: 'BG/SERV Hybrid', value: 'BG/SERV Hybrid' },
          { key: 'Buying Group', value: 'Buying Group' },
          { key: 'Buying Group (Limited Access);', value: 'Buying Group (Limited Access);' },
          { key: 'Buying Group PLUS User', value: 'Buying Group PLUS User' },
          { key: 'Call Center Manager', value: 'Call Center Manager' },
          { key: 'Call Center Supervisor', value: 'Call Center Supervisor' },
          { key: 'Claims 1', value: 'Claims 1' },
          { key: 'Claims 1 Plus', value: 'Claims 1 Plus' },
          { key: 'Claims Manager', value: 'Claims Manager' },
          { key: 'Claims Manager - View Only', value: 'Claims Manager - View Only' },
          { key: 'CSR 1', value: 'CSR 1' },
          { key: 'CSR 2', value: 'CSR 2' },
          { key: 'CSR 2 Plus', value: 'CSR 2 Plus' },
          { key: 'CSR 3', value: 'CSR 3' },
          { key: 'Data Admin - Limited', value: 'Data Admin - Limited' },
          { key: 'Data Administration', value: 'Data Administration' },
          { key: 'Dealer', value: 'Dealer' },
          { key: 'Dealer PLUS', value: 'Dealer PLUS' },
          { key: 'Sales', value: 'Sales' },
          { key: 'Special Team A-CC-SN', value: 'Special Team A-CC-SN' },
          { key: 'SSD - Limited', value: 'SSD - Limited' },
          { key: 'SSD - Lite', value: 'SSD - Lite' },
          { key: 'SSD - Med Internal Notes', value: 'SSD - Med Internal Notes' },
          { key: 'SSD-Med', value: 'SSD-Med' },
          { key: 'SSD-Plus', value: 'SSD-Plus' },
          { key: 'TESTING SP ROLE PCMI', value: 'TESTING SP ROLE PCMI' },
          { key: 'Customer', value: 'Customer' },
        ],
        isSingleselect: true,
        order: 4,
      }),
      new DropdownField({
        key: 'userProfileType',
        label: 'Link Type',
        filter: true,
        options: optionsProfileTypesList,
        isSingleselect: false,
        order: 5,
      }),
      new TextboxField({
        key: 'userProfileNumber',
        label: 'Link Number',
        order: 6,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getSecurityRolesFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'rolename ',
        label: 'Role Name',
        order: 1,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getSecurityPermissionConfigurationFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'category',
        label: 'Category',
        order: 0,
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 1,
      }),
      new TextboxField({
        key: 'component',
        label: 'Component',
        order: 2,
      }),
      new TextboxField({
        key: 'listName',
        label: 'List Name',
        order: 3,
      }),
      new TextboxField({
        key: 'elementName',
        label: 'Element Name',
        order: 4,
      }),
      new TextboxField({
        key: 'parentComponentName',
        label: 'Parent Component Name',
        order: 5,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getSecurityPermissionFields() {
    const optionsRoleNames = this.dropdownDataService.roleNameData
      ? this.dropdownDataService.roleNameData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];
    const operationsList = this.dropdownDataService.operationsList
      ? this.dropdownDataService.operationsList.map((x) => ({
          key: x.value,
          value: x.key,
        }))
      : [];

    const permissionsList = this.dropdownDataService.permissionsList
      ? this.dropdownDataService.permissionsList.map((x) => ({
          key: x.value,
          value: x.key,
        }))
      : [];
    const permissionDescription = this.dropdownDataService.permissionDescription
      ? this.dropdownDataService.permissionDescription.map((x) => ({
          key: x.value,
          value: x.key,
        }))
      : [];

    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'roleName',
        label: 'Role Name',
        filter: true,
        options: optionsRoleNames,
        order: 1,
      }),
      new DropdownField({
        key: 'permissionCategory',
        label: 'Permission Category',
        filter: true,
        options: permissionsList,
        order: 2,
      }),
      new DropdownField({
        key: 'permissionDescription',
        label: 'Permission Description',
        filter: true,
        isSingleselect: true,
        options: permissionDescription,
        order: 3,
      }),
      new DropdownField({
        key: 'operation',
        label: 'Operation',
        isSingleselect: true,
        options: operationsList,
        order: 4,
      }),
      new TextboxField({
        key: 'componentName',
        label: 'Component Name',
        order: 5,
      }),
      new TextboxField({
        key: 'elementName',
        label: 'Element Name',
        order: 6,
      }),
      // new TextboxField({
      //   key: 'parentComponentName',
      //   label: 'Parent Component Name',
      //   order: 7
      // })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getRolePermissionsFormAdd() {
    const optionsRoleNames = this.dropdownDataService.roleNameData
      ? this.dropdownDataService.roleNameData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];
    const permissionDescription = this.dropdownDataService.operationsListOriginal
      ? this.dropdownDataService.operationsListOriginal.map((x) => ({
          key: x.value,
          value: x.key,
        }))
      : [];
    const operationsList = this.dropdownDataService.operationsList
      ? this.dropdownDataService.operationsList.map((x) => ({
          key: x.value,
          value: x.key,
        }))
      : [];

    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'roleName',
        label: 'Role Name',
        filter: true,
        options: optionsRoleNames,
        order: 1,
        required: true,
      }),
      new DropdownField({
        key: 'permissionCategory',
        label: 'Permission Category',
        filter: true,
        isSingleselect: true,
        options: permissionDescription,
        order: 2,
        required: true,
      }),
      new DropdownField({
        key: 'operation',
        label: 'Operation',
        isSingleselect: true,
        options: operationsList,
        order: 3,
        required: true,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getSecurityRestatusFields() {
    const optionsClaimStatus = this.dropdownDataService.claimStatusData
      ? this.dropdownDataService.claimStatusData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];
    const optionsRoleNames = this.dropdownDataService.roleNameData
      ? this.dropdownDataService.roleNameData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'roleName',
        label: 'Role Name',
        options: optionsRoleNames,
        isSingleselect: false,
        order: 1,
      }),
      new DropdownField({
        key: 'fromStatus',
        label: 'From Status',
        filter: true,
        isSingleselect: false,
        options: optionsClaimStatus,
        order: 2,
      }),
      new DropdownField({
        key: 'toStatus',
        label: 'To Status',
        filter: true,
        isSingleselect: false,
        options: optionsClaimStatus,
        order: 3,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getAddSecurityRestatusFields() {
    const optionsClaimStatus = this.dropdownDataService.claimStatusData
      ? this.dropdownDataService.claimStatusData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];
    const optionsRoleNames = this.dropdownDataService.roleNameData
      ? this.dropdownDataService.roleNameData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'roleName',
        label: 'Role Name',
        options: optionsRoleNames,
        isSingleselect: false,
        required: true,
        order: 1,
      }),
      new DropdownField({
        key: 'fromStatus',
        label: 'From Status',
        filter: true,
        isSingleselect: true,
        options: optionsClaimStatus,
        required: true,
        order: 2,
      }),
      new DropdownField({
        key: 'toStatus',
        label: 'To Status',
        filter: true,
        isSingleselect: true,
        options: optionsClaimStatus,
        required: true,
        order: 3,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getEmailTemplateConfigurationFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'active',
        label: 'Active',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 1,
      }),
      new DropdownField({
        key: 'type',
        label: 'Type',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 2,
      }),
      new TextboxField({
        key: 'Message code',
        label: 'Message Code',
        order: 3,
      }),
      new TextboxField({
        key: 'description ',
        label: 'Description',
        order: 4,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getAdminMenuConfigurationFields() {
    const uniqueMenuTypeNames = removeDuplicatesFromArray(
      this.dropdownDataService.menuTypeList.map((x) => x.menuTypeName),
    );
    const menuTypeNameList =
      uniqueMenuTypeNames.length > 0
        ? uniqueMenuTypeNames.map((x) => ({
            key: x,
            value: x,
          }))
        : [];
    const moduleName = this.dropdownDataService.menuTypeList
      ? this.dropdownDataService.menuTypeList
          .map((x) => x.module)
          .filter((value, index, self) => self.indexOf(value) === index)
      : [];
    const filteredModuleNames = moduleName.map((value, key) => ({ key: value, value: key }));
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'module',
        label: 'Module',
        filter: true,
        isSingleselect: true,
        options: filteredModuleNames,
        order: 1,
      }),
      new DropdownField({
        key: 'menuTypeName',
        label: 'Menu Type',
        filter: true,
        isSingleselect: true,
        options: menuTypeNameList,
        order: 2,
      }),
      new TextboxField({
        key: 'key',
        label: 'Description',
        order: 3,
      }),
      new TextboxField({
        key: 'value',
        label: 'Code',
        order: 4,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getAdminMenuConfigFields() {
    const menuTypeNames = this.dropdownDataService.menuTypeList
      ? this.dropdownDataService.menuTypeList.map((x) => ({
          key: x.menuTypeName,
          value: x.sCMConfigIdOriginal,
        }))
      : [];
    const moduleName = this.dropdownDataService.menuTypeList
      ? this.dropdownDataService.menuTypeList
          .map((x) => x.module)
          .filter((value, index, self) => self.indexOf(value) === index)
      : [];
    const filteredModuleNames = moduleName.map((value, key) => ({ key: value, value: key }));
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'module',
        label: 'Module',
        filter: true,
        isSingleselect: true,
        required: true,
        isChangeEvent: true,
        options: filteredModuleNames,
        order: 1,
      }),
      new DropdownField({
        key: 'menuTypeName',
        label: 'Menu Type',
        filter: true,
        isSingleselect: true,
        required: true,
        options: menuTypeNames,
        order: 2,
      }),
      new TextboxField({
        key: 'key',
        label: 'Description',
        order: 3,
      }),
      new TextboxField({
        key: 'value',
        label: 'Code',
        order: 4,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getSecFormModalFields(options = { roles: [{ key: 'option1', value: 'option1' }] }) {
    const optionsMasterBuyingGroup = this.dropdownDataService.masterBuyingGroupData
      ? this.dropdownDataService.masterBuyingGroupData.map((x) => ({
          key: x.name,
          value: x.masterBuyingGroupIDOriginal,
        }))
      : [];
    const optionsBuyingGroup = this.dropdownDataService.buyingGroupData
      ? this.dropdownDataService.buyingGroupData.map((x) => ({
          key: x.name,
          value: x.agentIdOriginal,
        }))
      : [];
    const optionsDealerGroup = this.dropdownDataService.dealerGroupData
      ? this.dropdownDataService.dealerGroupData.map((x) => ({
          key: x.dealerGroupName,
          value: x.dealerGroupsIdOriginal,
        }))
      : [];
    const optionsWorkQueueList = this.dropdownDataService.workQueueListTypes.map((x) => ({
      key: x.key,
      value: x.key,
    }));
    const optionsServicerList = this.dropdownDataService.servicerListLinkData
      ? this.dropdownDataService.servicerListLinkData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];

    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'username',
        label: 'Username',
        order: 1,
      }),
      new TextboxField({
        key: 'email',
        label: 'Email',
        order: 2,
        required: true,
      }),
      new TextboxField({
        key: 'firstname',
        label: 'First Name',
        order: 3,
        required: true,
      }),
      new TextboxField({
        key: 'lastname',
        label: 'Last Name',
        order: 4,
        required: true,
      }),
      new TextboxField({
        key: 'phonenumber',
        label: 'Phone Number',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 5,
      }),
      new TextboxField({
        key: 'phoneextension',
        label: 'Phone Extension',
        order: 6,
      }),
      new DropdownField({
        key: 'accountstatus',
        label: 'Account Status',
        isSingleselect: true,
        value: { key: 'ACTIVE', value: 'ACTIVE' },
        options: [
          { key: 'ACTIVE', value: 'ACTIVE' },
          { key: 'INACTIVE', value: 'INACTIVE' },
        ],
        order: 7,
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'EDIT',
          permission: 'Change Status',
          category: 'User Security',
        },
      }),
      new DropdownField({
        key: 'role',
        label: 'Role',
        isSingleselect: true,
        options: options.roles,
        order: 8,
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'EDIT',
          permission: 'Change Role',
          category: 'User Security',
        },
      }),
      new DropdownField({
        key: 'masterBuyingGroup',
        label: 'Master Buying Group',
        filter: true,
        options: optionsMasterBuyingGroup,
        isSingleselect: false,
        order: 9,
      }),
      new DropdownField({
        key: 'buyingGroup',
        label: 'Buying Group',
        filter: true,
        options: optionsBuyingGroup,
        isSingleselect: false,
        order: 10,
      }),
      new DropdownField({
        key: 'dealerGroupNumber',
        label: 'Dealer',
        options: optionsDealerGroup,
        isSingleselect: false,
        filter: true,
        order: 11,
      }),
      new DropdownField({
        key: 'dealerLocation',
        label: 'Dealer Locations',
        filter: true,
        isSingleselect: false,
        order: 12,
      }),
      new DropdownField({
        key: 'queueType',
        label: 'My Work Queue',
        filter: true,
        isSingleselect: false,
        options: optionsWorkQueueList,
        order: 13,
      }),
      new DropdownField({
        key: 'servicer',
        label: 'Servicer',
        options: optionsServicerList,
        isSingleselect: false,
        filter: true,
        order: 14,
      }),
      // new TextboxField({
      //   key: 'userProfileNumber',
      //   label: 'Link Number',
      //   order: 15
      // }),
      // new TextboxField({
      //   key: 'userProfileName',
      //   label: 'Link Name',
      //   order: 16
      // }),
      // new TextboxField({
      //   key: 'disabled',
      //   label: ' ',
      //   disabled: true,
      //   order: 17
      // }),
      new TextboxField({
        key: 'createuserpassword',
        label: 'Create User Password',
        type: 'password',
        order: 18,
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'EDIT',
          permission: 'Change Password',
          category: 'User Security',
        },
      }),
      new TextboxField({
        key: 'confirmcreateuserpassword',
        label: 'Confirm Create User Password',
        type: 'password',
        order: 19,
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'EDIT',
          permission: 'Change Password',
          category: 'User Security',
        },
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getProcessClaimsFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'claimNumber',
        label: 'Claim Number',
        order: 1,
      }),
      new DropdownField({
        key: 'status',
        label: 'Status',
        isSingleselect: true,
        value: { key: 'APPROVED FOR PAYMENT', value: 'APPROVED FOR PAYMENT' },
        options: [
          { key: 'APPROVED FOR PAYMENT', value: 'APPROVED FOR PAYMENT' },
          { key: 'APPROVED FOR PAYMENT - UR', value: 'APPROVED FOR PAYMENT - UR' }
        ],
        order: 2,
      }),
      new TextboxField({
        key: 'payeeType',
        label: 'Payee Type',
        order: 3,
      }),
      new TextboxField({
        key: 'payeeNumber',
        label: 'Payee Number',
        order: 4,
      }),
      new TextboxField({
        key: 'payeeName',
        label: 'Payee Name',
        order: 5,
      }),
      new TextboxField({
        key: 'insuranceCarrier',
        label: 'Carrier',
        order: 6,
      }),
      new TextboxField({
        key: 'approvedForPaymentFrom',
        label: 'Approved For Payment',
        order: 7,
        type: 'Date',
      }),
      new TextboxField({
        key: 'approvedForPaymentTo',
        order: 8,
        type: 'Date',
      }),
      new TextboxField({
        key: 'approvedAmount',
        label: 'Amount Due',
        order: 9,
      }),
      new DropdownField({
        key: 'paymentType',
        label: 'Payment Type',
        isSingleselect: true,
        options: [
          { key: '', value: '' },
          { key: 'ACH', value: 'ACH' },
          { key: 'Check', value: 'Check' },
          { key: 'Credit', value: 'Credit' },
          { key: 'Credit Card', value: 'Credit Card' },
          { key: 'Dealer Statement Credit', value: 'Dealer Statement Credit' },
          { key: 'New Leaf Gift Card', value: 'New Leaf Gift Card' },
        ],
        order: 10,
      }),
      new TextboxField({
        key: 'dealer',
        label: 'Dealer',
        order: 11,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getProcessClaimModalFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'claim selected',
        label: 'Claims Selected',
        order: 1,
      }),
      new TextboxField({
        key: 'total payments',
        label: 'Total Payments',
        order: 2,
      }),
      new TextboxField({
        key: 'file format',
        label: 'File Format',
        type: 'File',
        order: 3,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getRateDetailsFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'markup',
        label: 'Markup',
        order: 1,
      }),

      new TextboxField({
        key: 'extendedDealerCost',
        label: 'Extended Dealer Cost',
        order: 2,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getcoverageTabFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'coverageDescription',
        label: 'Coverage Description',
        disabled: true,
        options: [
          { key: 'newRow', value: 'true' },
          { key: 'colClass', value: '12' },
        ],
        order: 1,
      }),

      new TextboxField({
        key: 'coverageCode',
        label: 'Coverage Code',
        disabled: true,
        options: [
          { key: 'newRow', value: 'true' },
          { key: 'colClass', value: '6' },
        ],
        order: 2,
      }),

      new TextboxField({
        key: 'deductibles',
        label: 'Deductibles',
        disabled: true,
        options: [
          { key: 'newRow', value: 'true' },
          { key: 'colClass', value: '6' },
        ],
        order: 3,
      }),

      new TextboxField({
        key: 'partsTerm',
        label: 'Term',
        disabled: true,
        options: [
          { key: 'newRow', value: 'true' },
          { key: 'colClass', value: '4' },
        ],
        order: 4,
      }),

      new TextboxField({
        key: 'serviceLocation',
        label: 'Service Location',
        disabled: true,
        options: [
          { key: 'newRow', value: 'true' },
          { key: 'colClass', value: '8' },
        ],
        order: 5,
      }),

      new TextboxField({
        key: 'retailRate',
        label: 'Retail Cost',
        options: [{ key: 'colClass', value: '4' }],
        order: 6,
      }),

      new TextboxField({
        key: 'retailTax1',
        label: 'Tax1',
        disabled: true,
        options: [
          { key: 'split', value: 'true' },
          { key: 'colClass', value: '4' },
        ],
        order: 7,
      }),

      new TextboxField({
        key: 'retailTax2',
        label: 'Tax2',
        disabled: true,
        options: [
          { key: 'split', value: 'true' },
          { key: 'colClass', value: '4' },
        ],
        order: 8,
      }),

      new TextboxField({
        key: 'grandTotal',
        label: 'Grand Total',
        disabled: true,
        options: [
          { key: 'split', value: 'true' },
          { key: 'colClass', value: '4' },
        ],
        order: 9,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getContractCancellationTabFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'customerCancellationDate',
        label: 'Customer Cancellation Date',
        type: 'Date',
        disabled: true,
        order: 1,
      }),

      new TextboxField({
        key: 'cancellationProcessDate',
        label: 'Cancellation Process Date',
        type: 'Date',
        disabled: true,
        order: 2,
      }),

      new TextboxField({
        key: 'cancellationTransactionDate',
        label: 'Cancellation Transaction Date',
        type: 'text',
        disabled: true,
        order: 3,
      }),

      new TextboxField({
        key: 'retailCost',
        label: 'Retail Cost',
        disabled: true,
        order: 4,
      }),

      new TextboxField({
        key: 'baseDealerCost',
        label: 'Base Dealer Cost',
        disabled: true,
        order: 5,
      }),

      new TextboxField({
        key: 'claimsApprovedPaid',
        label: 'Claims Approved Paid',
        disabled: true,
        order: 6,
      }),

      new TextboxField({
        key: 'cancellationReason',
        label: 'Cancellation Reason',
        disabled: true,
        order: 7,
      }),

      new TextboxField({
        key: 'cancellationMethod',
        label: 'Cancellation Method',
        disabled: true,
        order: 8,
      }),

      new TextboxField({
        key: 'dealerRefundSysGen',
        label: 'Dealer Refund (System Generated)',
        disabled: true,
        order: 9,
      }),

      new TextboxField({
        key: 'dealerRefundOverride',
        label: 'Dealer Refund (Override)',
        disabled: true,
        order: 10,
      }),

      new TextboxField({
        key: 'dealerRefundAdjusted',
        label: 'Dealer Refund (Adjusted)',
        disabled: true,
        order: 11,
      }),

      new TextboxField({
        key: 'cancellationFee',
        label: 'Cancellation Fee',
        disabled: true,
        order: 12,
      }),

      new TextboxField({
        key: 'termMonth',
        label: 'Term Month',
        disabled: true,
        order: 13,
      }),

      new TextboxField({
        key: 'refundFactor',
        label: 'Refund Factor',
        disabled: true,
        order: 14,
      }),

      new TextboxField({
        key: 'startDate',
        label: 'Start Date',
        disabled: true,
        order: 15,
      }),

      new TextboxField({
        key: 'usedDays',
        label: 'Used Days',
        disabled: true,
        order: 16,
      }),

      new TextboxField({
        key: 'typeOfMethod',
        label: 'Type Of Method',
        disabled: true,
        order: 17,
      }),

      new TextboxField({
        key: 'proRatePercentage',
        label: 'Pro Rate Percentage',
        disabled: true,
        order: 18,
      }),

      new TextboxField({
        key: 'refundBeforeCancellation',
        label: 'Refund Before Cancellation',
        disabled: true,
        order: 19,
      }),

      new TextboxField({
        key: 'freeLookUpPeriod',
        label: 'Free Lookup Period',
        disabled: true,
        order: 20,
      }),

      new TextboxField({
        key: 'cancellationValue',
        label: 'Cancellation Value',
        disabled: true,
        order: 21,
      }),

      new TextboxField({
        key: 'refundAfterCancellation',
        label: 'Refund After Cancellation',
        disabled: true,
        order: 22,
      }),

      new TextboxField({
        key: 'finalRefund',
        label: 'Final Refund',
        disabled: true,
        order: 23,
      }),

      new TextboxField({
        key: 'finalCancellationFee',
        label: 'Final Cancellation Fee',
        disabled: true,
        order: 24,
      }),

      new TextboxField({
        key: 'dealerExclusion',
        label: 'Dealer Exclusion',
        disabled: true,
        order: 25,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getContractCancellationModalFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'customerCancellationDate',
        label: 'Customer Cancellation Date',
        type: 'Date',
        order: 1,
        required: true,
      }),

      new TextboxField({
        key: 'cancellationTransactionDate',
        label: 'Cancellation Transaction Date',
        type: 'Date',
        order: 2,
        required: true,
      }),

      new TextboxField({
        key: 'cancellationProcessDate',
        label: 'Cancellation Process Date',
        type: 'Date',
        disabled: true,
        required: true,
        order: 3,
      }),

      new TextboxField({
        key: 'refundToCustomerOverride',
        label: 'Refund To Customer (Override)',
        type: 'number',
        required: true,
        order: 4,
      }),

      new TextboxField({
        key: 'dealerRefundOverride',
        label: 'Dealer Refund (Override)',
        type: 'number',
        required: true,
        order: 5,
      }),

      new TextboxField({
        key: 'cancellationFee',
        label: 'Cancellation Fee',
        type: 'number',
        order: 6,
      }),

      new DropdownField({
        key: 'cancellationReason',
        label: 'Cancellation Reason',
        isSingleselect: true,
        options: [
          { key: 'Customer Non-Payment', value: 'Customer Non-Payment' },
          { key: 'Customer Requested', value: 'Customer Requested' },
          { key: 'Dealer Non-Payment', value: 'Dealer Non-Payment' },
          { key: 'Dealer Requested', value: 'Dealer Requested' },
          { key: 'Entry Error', value: 'Entry Error' },
        ],
        required: true,
        order: 7,
      }),

      new DropdownField({
        key: 'cancellationMethod',
        label: 'Cancellation Method',
        isChangeEvent: true,
        isSingleselect: true,
        options: [
          { key: 'Override', value: 'Override' },
          { key: 'Pro-Rata', value: 'Pro-Rata' },
          { key: 'Full Refund', value: 'Full Refund' },
        ],
        required: true,
        order: 8,
      }),

      new TextboxField({
        key: 'claims',
        label: 'Claims',
        disabled: true,
        type: 'number',
        order: 9,
      }),

      new TextboxField({
        key: 'customerRetailCost',
        label: 'Customer Retail Cost',
        disabled: true,
        type: 'number',
        order: 10,
      }),

      new TextboxField({
        key: 'refundToCustomerSysGen',
        label: 'Refund To Customer (SysGen)',
        disabled: true,
        type: 'number',
        order: 11,
      }),

      new TextboxField({
        key: 'dealerRefundSysGen',
        label: 'Dealer Refund (SysGen)',
        disabled: true,
        type: 'number',
        order: 12,
      }),

      new CheckBoxField({
        key: 'isMJC',
        label: '',
        type: 'checkbox',
        options: [{ key: 'isMJC', value: 'Is MJC Contract Available' }],
        disabled: false,
        order: 13,
      }),

      new TextboxField({
        key: 'mjcContract',
        label: 'MJC Contract Number',
        disabled: false,
        type: 'text',
        order: 14,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getDocumentSearchFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'fileName',
        label: 'File Name',
        order: 1,
      }),

      new DropdownField({
        key: 'attachmentsType',
        label: 'Attachments Type',
        isSingleselect: true,
        value: { key: 'Agent', value: 0 },
        options: this.roleService.validateSearch(this.importFileService.getDocumentOptions()),
        order: 2,
      }),

      new TextboxField({
        key: 'uploadedDate',
        label: 'Uploaded Date',
        type: 'Date',
        order: 3,
      }),

      new TextboxField({
        key: 'limit',
        label: 'Number of records',
        order: 4,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getClaimsDocumentSearchFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'fileName',
        label: 'File Name',
        order: 1,
      }),

      new DropdownField({
        key: 'attachmentsType',
        label: 'Attachments Type',
        value: { key: 'ClaimPayee', value: 9 },
        isSingleselect: true,
        disabled: true,
        options: [{ key: 'ClaimPayee', value: 9 }],
        order: 2,
      }),

      new TextboxField({
        key: 'uploadedDate',
        label: 'Uploaded Date',
        type: 'Date',
        order: 3,
      }),

      new TextboxField({
        key: 'limit',
        label: 'Number of records',
        order: 4,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
