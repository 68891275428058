import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContractFormFieldService } from '../../services/contract-form-field.service';
import { FormField } from '../../shared/form-field/form-field';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { GeneralFieldsComponent } from './general-fields/general-fields.component';
import { CustomerInformationComponent } from './customer-information/customer-information.component';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import { CoveredProductsComponent } from './covered-products/covered-products.component';
import { AddressComponent } from '../../shared/address/address.component';
import { AssociatedContractsComponent } from './associated-contracts/associated-contracts.component';
import { TabSpinnerComponent } from '../../shared/tab-spinner/tab-spinner.component';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../interfaces/role-authorization.interface';
import { ContractService } from '../contract.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { copy, removeDuplicatesFromArray } from '../../shared/utilities/common-utilities';
import { Subscription } from 'rxjs';
import { AlertService } from '../../services/alert.service';
@Component({
  selector: 'contract-main-tab',
  templateUrl: 'contract-main-tab.template.html',
  styleUrls: ['contract-main-tab.scss'],
  providers: [],
})
export class ContractMainTabComponent implements OnInit, OnDestroy {
  searchParams: any;
  additionalInfo: FormField<any>[] = [];
  customerInfo: FormField<any>[] = [];
  generalInfo: FormField<any>[] = [];
  authDetails: IRoleAuthorizationService;
  componentDisplay = {
    CustomerInformationComponent: null,
    AddressComponent: null,
    CoveredProductsComponent: null,
    AssociatedContractsComponent: null,
    GeneralFieldsComponent: null,
    AdditionalInfoComponent: null,
  };
  querySubscription: any;
  querySubscriptions: Subscription[] = [];
  isMainTabDataAvailable: boolean;
  isLoading: boolean;
  genericFormData: any;
  payLoad: any;
  modelData: any;
  initialLoad = false;
  component = ContractMainTabComponent;
  constructor(
    private contractService: ContractFormFieldService,
    private roleService: RoleAuthorizationService,
    public contractDataService: ContractService,
    private azureService: AzureLoginService,
    private alertService: AlertService,
  ) {}
  ngOnInit() {
    this.componentDisplay = {
      CustomerInformationComponent: this.roleService.isVisible(CustomerInformationComponent),
      AddressComponent: this.roleService.isVisible(AddressComponent),
      CoveredProductsComponent: this.roleService.isVisible(CoveredProductsComponent),
      AssociatedContractsComponent: this.roleService.isVisible(AssociatedContractsComponent),
      GeneralFieldsComponent: this.roleService.isVisible(GeneralFieldsComponent),
      AdditionalInfoComponent: this.roleService.isVisible(AdditionalInfoComponent),
    };
    this.contractService.getAdditionalInfoFields().subscribe((additionalInfo) => {
      this.additionalInfo = additionalInfo;
    });
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: AdditionalInfoComponent,
      generalArray: this.additionalInfo,
    };
    this.additionalInfo = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.contractService
      .getGeneralFields()
      .subscribe((generalInfo) => (this.generalInfo = generalInfo));
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: GeneralFieldsComponent,
      generalArray: this.generalInfo,
    };
    this.generalInfo = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.contractService
      .getContractCustomerInfo()
      .subscribe((customerInfo) => (this.customerInfo = customerInfo));
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: CustomerInformationComponent,
      generalArray: this.customerInfo,
    };
    this.customerInfo = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.searchParams =
      this.contractDataService.contractConfig.searchParams &&
      this.contractDataService.contractConfig.searchParams.id;
    this.initialLoad = true;
    // get data
    if (
      this.contractDataService.contractConfig.searchParams &&
      this.contractDataService.contractConfig.searchParams.id
    ) {
      this.contractDataService.isLoading.next(true);
      this.querySubscriptions.push(
        this.contractDataService
          .getContractMainTab(
            this.contractDataService.contractConfig.searchParams.id,
            this.azureService.accountId,
          )
          .subscribe(
            ({ data, loading }: any) => {
              this.modelData = copy(data.getContractProfileById);
              if (this.modelData) {
                this.contractDataService.contractDataModel.mainTabPrev = {
                  addresses: Object.assign({}, this.modelData.addresses[0]),
                };
                this.contractDataService.contractDataModel.mainTab = {
                  customerInformation: this.modelData.contactInformation,
                  addresses: this.modelData.addresses[0],
                  coveredProducts: this.modelData.coveredProducts,
                  associatedContracts: this.modelData.associatedContracts,
                  productTypeDescription: this.modelData.productTypeDescription,
                  generalFields: {
                    general1: this.modelData.general1,
                    general2: this.modelData.general2,
                    general3: this.modelData.general3,
                    general4: this.modelData.general4,
                  },
                  additionalInformation: {
                    storeLocationName: this.modelData.storeLocationName,
                    storeLocationNumber: this.modelData.storeLocationNumber,
                    dealerGroupName: this.modelData.dealerGroupName,
                    agentName: this.modelData.agentName,
                    salesClerk: this.modelData.salesClerk,
                    masterBuyingGroup: this.modelData.masterBuyingGroup,
                    dealerGroupNumber: this.modelData.dealerGroupNumber,
                    phoneNumber1:
                      this.modelData.sellerInfo &&
                      this.modelData.sellerInfo.contactInfo &&
                      this.modelData.sellerInfo.contactInfo.phoneNumber1
                        ? this.modelData.sellerInfo.contactInfo.phoneNumber1
                        : '',
                    tSelfServicing:
                      this.modelData.dealerInfo && this.modelData.dealerInfo.tSelfServicing
                        ? 'Yes'
                        : 'No',
                  },
                  coverageInformation: {
                    coverageCode: this.modelData.coverageCode,
                    coverageDescription: this.modelData.coverageDescription,
                    productTypeCode: this.modelData.productTypeCode,
                    sKUReferenceCode: this.modelData.sKUReferenceCode,
                    insurerNumber: this.modelData.insurerNumber,
                    retailRate: this.modelData.retailRate,
                  },
                };
                this.contractDataService.contractDataModel.coveragePlanDetails =
                  this.modelData.coverageInformation;
                if (this.initialLoad) {
                  this.getAlertData();
                }
                this.contractDataService.contractDataModel.soClaims = this.modelData.soClaims;
                this.contractDataService.contractDataModel.coverageInformation =
                  this.modelData.coverageInformation;
                this.contractDataService.setMainTabAvailability(true);
                this.isMainTabDataAvailable = true;
              }
              this.genericFormData = {
                general1: this.modelData.general1,
                general2: this.modelData.general2,
                general3: this.modelData.general3,
                general4: this.modelData.general4,
              };
              this.contractDataService.isLoading.next(false);
            },
            (err) => {
              this.contractDataService.isLoading.next(false);
              throw err;
            },
          ),
      );
    }
  }

  getAlertData() {
    this.initialLoad = false;
    const { mainTab, coveragePlanDetails } = this.contractDataService.contractDataModel;
    const coveredProducts =
      mainTab.coveredProducts && mainTab.coveredProducts.length > 0 ? mainTab.coveredProducts : [];
    const subCategories = [];
    const categories = [];
    const manufacturers = [];
    if (coveredProducts.length > 0) {
      coveredProducts.forEach((coveredProduct) => {
        subCategories.push(coveredProduct.subcategoryDescription);
        categories.push(coveredProduct.categoryDescription);
        manufacturers.push(coveredProduct.manufacturerManufacturer);
      });
    }
    const { additionalInformation } = mainTab;
    const prodTypeDescription = coveragePlanDetails ? [coveragePlanDetails.planType] : [];
    const alertData = {
      moduleName: ['Contract'],
      productPlanTypes: prodTypeDescription,
      subCategories: removeDuplicatesFromArray(subCategories),
      manufacturers: removeDuplicatesFromArray(manufacturers),
      buyingGroups:
        additionalInformation && additionalInformation.agentName
          ? [additionalInformation.agentName]
          : [],
      dealers:
        additionalInformation && additionalInformation.dealerGroupName
          ? [additionalInformation.dealerGroupName]
          : [],
      dealerLocations:
        additionalInformation && additionalInformation.storeLocationName
          ? [additionalInformation.storeLocationName]
          : [],
      category: removeDuplicatesFromArray(categories),
    };
    this.querySubscriptions.push(
      this.alertService.getApplicableAlerts(alertData).subscribe(
        ({ data, loading }: any) => {
          this.alertService.alerts = copy(data.getApplicableAlerts);
          this.alertService.showAlert();
        },
        (err) => {
          throw err;
        },
      ),
    );
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  // Disabled form fields do not show from this.form.valueChanges. Use previous value for these.
  modelDataChange(data, component) {
    switch (component) {
      case 'generalInfo':
        this.contractDataService.contractDataModel.mainTab.generalFields = {
          ...this.contractDataService.contractDataModel.mainTab.generalFields,
          ...data,
        };
        break;
      case 'additionalInfo':
        this.contractDataService.contractDataModel.mainTab.additionalInformation = {
          ...this.contractDataService.contractDataModel.mainTab.additionalInformation,
          ...data,
        };
        break;
      case 'customerInfo':
        this.contractDataService.contractDataModel.mainTab.customerInformation = {
          ...this.contractDataService.contractDataModel.mainTab.customerInformation,
          ...data,
        };
        break;
    }
  }
}
