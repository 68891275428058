import { Component, Output, EventEmitter, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { ServicePowerModalService } from './service-power-modal.service';
import { EmailService } from '../../../services/email.service';
import { MessageService } from 'primeng/api';
import { ChangeDetectorRef } from '@angular/core';
import { ServicePowerTableService } from './service-power-table/service-power-table.service';
import { ServiceOrderService } from '../service-order.service';
import { ServiceOrderDataService } from '../service-order-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'service-power-modal',
  templateUrl: 'service-power-modal.template.html',
  styleUrls: ['./service-power-modal.scss'],
  providers: [
    FormFieldControlService,
    ServicePowerModalService,
    EmailService,
    MessageService,
    ServicePowerTableService,
  ],
})
export class ServicePowerModalComponent implements OnInit, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() isReschedule = false;
  @Output() formSubmitEvent = new EventEmitter<any>();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();

  formFields: FormField<string>[] = [];
  form: FormGroup;

  formFields1: FormField<string>[] = [];
  formFields2: FormField<string>[] = [];
  displaySeviceBenchModal: true;
  isLoading = false;
  querySubscriptions: Subscription[] = [];

  constructor(
    private qcs: FormFieldControlService,
    private servicePowerModalService: ServicePowerModalService,
    private serviceOrderService: ServiceOrderService,
    private serviceOrderDataService: ServiceOrderDataService,
    private emailService: EmailService,
    private messageService: MessageService,
    private chRef: ChangeDetectorRef,
    private spTableService: ServicePowerTableService,
  ) {
    this.servicePowerModalService.getSendEmailFields().subscribe((data) => {
      this.formFields = data;
    });
  }
  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    this.formFields1 = this.formFields.slice(0, 6);
    this.formFields2 = this.formFields.slice(11, 30);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
  }
  onSubmit() {
    if (this.spTableService.selectedValue.length === 0) {
      window.alert('No Timeslot Selected');
      return;
    }
    this.isLoading = true;
    this.querySubscriptions.push(
      this.spTableService
        .scheduleJob(this.spTableService.selectedValue, this.isReschedule)
        .subscribe(
          (data) => {
            // console.log('Job Schedule Response: ' + JSON.stringify(data));
            const body = data.spCallAssignmentDetails['soapenv:Envelope']['soapenv:Body'];
            const ackMessage = body && body.callAssignmentRsp.AckMsg;
            if (ackMessage._text === 'OK') {
              if (data.isClaimDataChanged) {
                this.serviceOrderService.claimDataChanged.next(true);
              }
              if (data.isNoteChanged) {
                this.serviceOrderService.claimNotesChanged.next(true);
              }
              this.querySubscriptions.push(
                this.serviceOrderService.isClaimDataUpdated.subscribe({
                  next: () => {
                    this.isLoading = false;
                    this.serviceOrderDataService.serviceOrderDataModel.isThirdPartyDispatch = true;
                    this.formSubmitEvent.emit(true);
                    this.displayDialog = false;
                  },
                }),
              );
            } else {
              this.isLoading = false;
              const errorMsgArray = ackMessage._text.split(': ');
              let errorMsgDetail = ackMessage._text;
              if (errorMsgArray.length > 1) {
                errorMsgDetail = errorMsgArray[0].startsWith('FSS-EXT')
                  ? `We have encountered an issue with the Downstream system. Error Code:${errorMsgArray[0]}`
                  : `${errorMsgArray[1]}. Error Code:${errorMsgArray[0]}`;
              }
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: `ERROR: ${errorMsgDetail}.`,
              });
            }
          },
          (err) => {
            this.isLoading = false;
            this.displayDialog = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `ERROR: There was an error scheduling the job.`,
            });
          },
        ),
    );
  }

  onReschedule() {
    this.onSubmit();
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
